import { useState, useEffect } from 'react';
import moment from 'moment';

function backspaceListener(event) {
  if (typeof(window) !== 'undefined') {
    window.GLOBAL_BACKSPACE = event.keyCode === 8;
  }
}


function updateBackspaceForInput() {
  if (typeof(window) !== 'undefined') {
    const currentElement = document.activeElement;

    if (currentElement.tagName.toLowerCase() === 'input') {
      currentElement.removeEventListener('keydown', backspaceListener);
      currentElement.addEventListener('keydown', backspaceListener);
    }
  }
}

export function useHourTimer(startDate) {
  const [countdown, setCountdown] = useState(null);
  let x;


  useEffect(() => () => clearInterval(x), [x]);

  if (!startDate) {
    return 'Coming Soon';
  }


  if (moment(startDate).diff(moment(), 'days') < 1) {
    x = setInterval(function () {
      const c = moment(startDate).diff(moment(), 'seconds');
      var seconds = String(Math.floor(c % 60)).padStart(2, 0);
      var minutes = String(Math.floor((c/60) % 60)).padStart(2, 0);
      var hours = String(Math.floor(c/(60*60))).padStart(2, 0);

      // Output the result in an element with id="demo"
      setCountdown(hours + 'h '
    + minutes + 'm ' + seconds + 's ');

      // If the count down is over, write some text
      if ((hours + minutes + seconds) < 0) {
        clearInterval(x);
        setCountdown('EXPIRED');
      }
    }, 1000);
  } else {
    return moment(startDate).format('MMM Do, h:mm A');
  }
  return countdown;
}

export function creditCardMask(cc) {
  cc = cc || '';
  updateBackspaceForInput();
  cc = cc.replace(/\D+/g, '');
  const replace4 = str => str.replace(/(\d{4})/, '$1-');
  const replace8 = str => str.replace(/(\d{4})(\d{4})/, '$1-$2-');
  const replace12 = str => str.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-');
  const replace16 = str =>
    str.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

  if (cc.length >= 16) {
    return replace16(cc.substr(0, 16));
  } else if (cc.length >= 12) {
    cc = replace12(cc);
  } else if (cc.length >= 8) {
    cc = replace8(cc);
  } else if (cc.length >= 4) {
    cc = replace4(cc);
  }

  if (window.GLOBAL_BACKSPACE) {
    if (cc.length === 5 || cc.length === 10 || cc.length === 15) {
      //last char is not a digit
      cc = cc.slice(0, -1);
    }
  }
  return cc;
}

export function usePhoneNumberMask(phoneNumber) {
  phoneNumber = phoneNumber || '';
  updateBackspaceForInput();
  const replace3 = str => str.replace(/(\d{3})/, '$1-');
  const replace7 = str => str.replace(/(\d{3})(\d{3})/, '$1-$2-');

  if (typeof(window) !== 'undefined' && window.GLOBAL_BACKSPACE) {
    if (
      /\D/gi.test(phoneNumber[phoneNumber.length - 1]) ||
      phoneNumber.length === 3 ||
      phoneNumber.length === 6
    ) {
      //last char is not a digit
      return phoneNumber.slice(0, -1);
    } else {
      return phoneNumber;
    }
  }
  phoneNumber = phoneNumber.replace('/', ' ').replace(/\D+/g, '');

  if (phoneNumber[0] == 1) {
    phoneNumber = phoneNumber.slice(1);
  }

  let phoneNumber2 = phoneNumber.substr(0, 10);

  if (phoneNumber2.length >= 7) {
    return replace7(phoneNumber2);
  } else if (phoneNumber2.length >= 3) {
    return replace3(phoneNumber2);
  } else {
    return phoneNumber;
  }
}

export function formatAmount(amount) {
  return numberWithCommas(amount.toFixed(2));
}

export function numberWithCommas(x = '') {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}